@import "root/root";

@import "components/datePicker";
@import "components/multirange";
@import "components/dropbox";
@import "components/ToastStyle";

@tailwind base;
@tailwind components;
@tailwind utilities;
